import {
  Button,
  Checkbox, Dialog, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
  const [nda, setNda] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [info, setInfo] = useState(false);
  const [topic, setTopic] = useState('web');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const handleSelectChange = (event) => {
    setTopic(event.target.value);
  };
  const { handleSubmit, register, reset } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    data.nda = nda;
    data.topic = topic;
    if (policy) {
      setLoading(true);
      fetch('/.netlify/functions/iqc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((r) => {
          setLoading(false);
          if (r.ok) {
            reset({
              first: '', last: '', email: '', phone: '', msg: '',
            });
            setSuccess('Dziękujemy za wysłanie wiadomości! Wkrótce skontaktujemy się z Państwem.');
          } else {
            setSuccess('Wystąpił błąd przy wysyłaniu wiadomości. Prosimy spróbować ponownie.');
          }
        })
        .catch((err) => {
          setLoading(false);
          setSuccess('Wystąpił błąd przy wysyłaniu wiadomości. Prosimy spróbować ponownie.');
        });
    } else {
      setSuccess('Prosimy o zapoznanie się z informacją o przetwarzaniu danych osobowych.');
    }
  };
  return (
    <form
      style={{ marginTop: '30px' }}
      onSubmit={handleSubmit(onSubmit)}
    >

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Temat zapytania</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={topic}
              onChange={handleSelectChange}
            >
              <MenuItem value="web">Strony i serwisy internetowe</MenuItem>
              <MenuItem value="mobile">Aplikacje mobilne</MenuItem>
              <MenuItem value="erp">Systemy CRM/ERP</MenuItem>
              <MenuItem value="pwa">Aplikacje progresywne (PWA)</MenuItem>
              <MenuItem value="devops">Usługi DevOps</MenuItem>
              <MenuItem value="consulting">Usługi consultingowe</MenuItem>
              <MenuItem value="nextcloud">Wdrożenia Nextcloud</MenuItem>
              <MenuItem value="inne">Pozostałe zapytania</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register({ required: true })} name="first" label="Imię" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register} name="last" label="Nazwisko" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register} name="email" type="email" label="Adres e-mail" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register} name="phone" type="tel" label="Numer telefonu" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField inputRef={register} name="msg" label="Wiadomość" multiline variant="standard" fullWidth />
        </Grid>
        <Grid item xs={12}>

          <FormControlLabel
            control={(
              <Checkbox
                checked={policy}
                name="policy"
                color="primary"
                onClick={() => { setPolicy(!policy); }}
              />
        )}
            label={(
              <>
                Zapoznałem/łam się z
                {' '}
                <a onClick={() => { setInfo(true); }}>informacją o przetwarzaniu danych osobowych</a>
                .
              </>
)}
            style={{ marginBottom: '6px' }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={nda}
                name="nda"
                color="primary"
                onClick={() => { setNda(!nda); }}
              />
        )}
            label="Proszę o przygotowanie umowy o zachowanie poufności (NDA)."
          />

        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">Wyślij</Button>
        </Grid>
      </Grid>
      <Dialog open={info} onClose={() => { setInfo(false); }}>
        <DialogTitle>
          Informacja o przetwarzaniu danych osobowych
        </DialogTitle>
        <div>
          <p style={{ padding: '0px 12px' }}>
            Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych
            osobowych w celu udzielenia odpowiedzi na wpisane zapytanie. Jednocześnie oświadczam, że
            zostałam poinformowany o przysługującym mi prawie dostępu do treści moich danych oraz
            ich poprawiania, wycofania zgody na ich przetwarzanie w każdym czasie, jak również, że
            podanie tych danych było dobrowolne. Administratorem danych jest firma IQcode
            Technologie Internetowe Paweł Kuffel z siedzibą w Warszawie przy ulicy Jana Karola
            Chodkiewicza 7/22, kod pocztowy: 02-593.
          </p>
          <Button disabled={loading} style={{ margin: '20px' }} variant="contained" onClick={() => { setInfo(false); }}>
            Zamknij
          </Button>
        </div>
      </Dialog>
      <Snackbar open={success !== ''} autoHideDuration={6000} message={success} onClose={() => { setSuccess(''); }} />
    </form>
  );
};

export default ContactForm;
