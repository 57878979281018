import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Container, Fab, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocationIcon from '@material-ui/icons/LocationOn';
import EuroIcon from '@material-ui/icons/Euro';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import FeatureCardsSection from '../components/featureCardsSection';
import KompleksowaUsluga from '../../assets/svg/iqcode-technologie-stack.svg';
import CzasRealizacji from '../../assets/svg/metodyki-zwinne-agile.svg';
import Gwarancja from '../../assets/svg/gwarancje-wsparcie-sla.svg';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import LogoMain from '../../assets/svg/logo-full-alt-big.svg';
import ContactForm from '../components/contactForm';
import theme from '../theme/theme';
import SEO from '../components/seo';

const ContactCard = styled.div`
  padding:  4px 16px;
  background-color: white;
  box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  li {
    margin-top: 12px;
    font-size: 18px;
  }

  svg {
    vertical-align: sub;
    color: ${theme.primary};
  }

  h4 {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 22px;
    color: #333;
    svg {
      margin-bottom: 15px;
      font-size: 30px;
    }
  }

  &:hover {
        box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.16);
        background-color: ${theme.primary};
        color: white;
        transition: 0.5s ease;
        h5, li, svg, h4 {
          color: white;
        }
    }
`;

const ContactPage = () => {
  const data = useStaticQuery(graphql`
        query ContactImages {
            kompleksowacontact: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          <span>Kontakt</span>
        </>
              )}
      subtitle="Porozmawiajmy o Państwa projekcie"
      img="/img/biznes-kontakt.jpg"
    >
      <SEO title="Kontakt - iqcode Software House w Warszawie" description="Kontakt z iqcode - Software House działającym w Warszawie i specjalizującym się w tworzeniu aplikacji progresywnych (PWA) oraz architekturze microservices." />
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://iqcode.pl",
          "name": "iqcode Technologie Internetowe Paweł Kuffel",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48-608-775-280",
            "contactType": "Sales"
          }
        }
      `}
        </script>
        <title>Kontakt - iqcode Software House Warszawa</title>
      </Helmet>
      <Container className="mwrs">
        <Grid spacing={4} container>

          <Grid item xs={12} sm={6}>
            <h2>
              Dane
              {' '}
              <span>Kontaktowe</span>
            </h2>
            <div style={{
              display: 'block', margin: '16px 0px', padding: '5px 0px',
            }}
            >
              <p>
                Z powodu pandemii COVID-19, pracujemy zdalnie w modelu
                {' '}
                <i>home office</i>
                .
              </p>
            </div>
            <Grid spacing={3} container>

              <Grid item sm={6} xs={12}>
                <a href="tel:+48511639264">
                  <ContactCard>
                    <h4>
                      <PhoneIcon />
                      <br />
                      608 775 280
                    </h4>
                  </ContactCard>
                </a>
              </Grid>
              <Grid item sm={6} xs={12}>
                <a href="mailto:kontakt@iqcode.pl">
                  <ContactCard>
                    <h4>
                      <MailIcon />
                      <br />
                      kontakt@iqcode.pl
                    </h4>
                  </ContactCard>
                </a>
              </Grid>
              <Grid item sm={12} xs={12}>
                <ContactCard>
                  <h4>
                    Dane
                    {' '}
                    <span>Firmy</span>
                  </h4>
                  <ul>
                    <li>
                      <AccountBalanceIcon />
                      {' '}
                      IQcode Technologie Internetowe Paweł
                      Kuffel
                    </li>
                    <li>
                      <LocationIcon />
                      {' '}
                      Chodkiewicza 7/22, 02-593 Warszawa
                    </li>
                    <li>
                      <EuroIcon />
                      {' '}
                      PL7811977904 (VAT EU)
                    </li>
                    <li>
                      <ScheduleIcon />
                      {' '}
                      pon - pt, 9:00-18:00
                    </li>
                  </ul>
                </ContactCard>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={6}>
            <h2>
              Formularz
              {' '}
              <span>Kontaktowy</span>
            </h2>

            <ContactForm />
          </Grid>
        </Grid>
      </Container>

      <div
        className="promo-light"
        style={{ marginBottom: '60px', marginTop: '30px' }}
      >
        <div className="promo-light-diagonal" />
        <div className="container rel">
          <div className="row">
            <div className="col s12 m12 l6 xl6">
              <div className="promo-left">
                <h3>52 MINUTY</h3>
                <h6>
                  Średni czas odpowiedzi na zapytanie ofertowe wysłane w godzinach naszej pracy.
                </h6>
              </div>
            </div>
            <div className="col s12 m12 l6 xl6">
              <div className="promo-right">
                <h3>72 GODZINY</h3>
                <h6>
                  Tyle czasu maksymalnie zajmuje nam sporządzenie wyceny
                  fixed-price
                  projektu.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Grid container>
          <Grid item sm={12} md={8}>
            <h2>
              Działamy w całej
              {' '}
              <span>Polsce</span>
            </h2>
            <p>
              Choć siedziba
              {' '}
              <strong>iqcode</strong>
              {' '}
              znajduje się
              na warszawskim Mokotowie, świadczymy usługi dla
              klientów z całej Polski. Od
              naszego startu w 2018 roku, wykonaliśmy kilkadziesiąt projektów programistycznych dla
              firm z wielu branż. Do najliczniejszej grupy naszych klientów
              należą przedsiębiorstwa z
              {' '}
              <strong>Poznania</strong>
              {' '}
              oraz
              {' '}
              <strong>Warszawy</strong>
              .
            </p>
            <p style={{ marginBottom: '50px' }}>
              Niemal wszystkie rozpoczęte u nas projekty kończą się długotrwałą współpracą skrojoną na miarę
              przedsiębiorstwa klienta. Ponieważ dysponujemy bogatym zestawem technologii oraz
              posiadamy liczne obszary specjalizacji, nie jesteśmy ograniczani przy rozwoju projektów
              naszych klientów. Do naszych obszarów specjalizacji należy tworzenie
              {' '}
              <Link to="/aplikacje-serwerowe/">aplikacji serwerowych</Link>
              {' '}
              w architekturze mikrousług (
              <i>microservices architecture</i>
              ) uruchamianych w środowisku
              {' '}
              <Link to="/devops/">Kubernetes</Link>
              ,
              budowa wydajnych i interaktywnych
              {' '}
              <Link to="/strony-internetowe/">stron i serwisów internetowych</Link>
              {' '}
              {' '}
              w technologii Gatsby.js
              oraz tworzenie
              {' '}
              <Link to="/aplikacje-progresywne-pwa/">
                progresywnych
                aplikacji webowych
              </Link>
              {' '}
              (PWA).
            </p>
          </Grid>
          <Grid item sm={12} md={4} style={{ padding: '30px' }}>
            <LogoMain className="inline-svg-fw" />
          </Grid>
        </Grid>
      </Container>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Porozmawiajmy o Twoim
            {' '}
            <span>Projekcie</span>
            !
          </h3>
          <h4>
            Wycenę
            {' '}
            <i>fixed price</i>
            {' '}
            prześlemy w ciągu 72h
          </h4>
          <a className="btn" href="mailto:kontakt@iqcode.pl">Napisz do nas!</a>
        </div>
      </div>
      <FeatureCardsSection
        title={(
          <>
            Dlaczego
            {' '}
            <span>warto</span>
          </>
                  )}
        subtitle="Realizować projekt w iqcode"
        cards={[
          {
            title: (
              <>
                Nowoczesne
                <br />
                Technologie
              </>
            ),
            icon: <KompleksowaUsluga />,
            link: 'Nasze technologie',
            to: '/technologie/',
            desc:
                            'PHP i jQuery? To nie u nas. Stosujemy nowoczesne rozwiązania pozbawione długu technologicznego',
          },
          {
            title: (
              <>
                Metodyki
                <br />
                Zwinne
              </>
            ),
            icon: <CzasRealizacji />,
            link: 'Omówmy współpracę',
            to: '/kontakt/',
            desc:
                            'Projekty realizujemy w sposób iteracyjny z zaangażowaniem klienta',
          },
          {
            title: (
              <>
                Gwarancje i
                <br />
                Umowy SLA
              </>
            ),
            icon: <Gwarancja />,
            link: 'DevOps w iqcode',
            to: '/devops/',
            desc:
                            'Udzielamy pisemnych gwarancji na wykonane produkty cyfrowe z rygorystycznymi warunkami SLA',
          },
        ]}
      />
      <AllServices />

      <Fab
        color="primary"
        aria-label="call"
        style={{
          position: 'fixed', bottom: '16px', right: '16px', zIndex: 1000,
        }}
        href="tel:+48608775280"
      >
        <PhoneIcon />
      </Fab>
    </SubLayout>
  );
};

export default ContactPage;
